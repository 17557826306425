import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHourglassStart,
  faHourglassEnd,
} from '@fortawesome/free-solid-svg-icons';

import { useInvitations } from '../api/getInvitations';
import { Invitation } from '../types';

type UDFInvitations = Invitation[] | undefined;

/**
 * Invitation list for current company
 * @date 2024/09/12
 */
export const InvitationList = ({ companyId }: { companyId: string }) => {
  const queryResult = useInvitations({ companyId });
  const invitations = (queryResult.data as UDFInvitations) || [];
  // console.log(invitations);

  const isExpired = (ivt: Invitation) => {
    return new Date() > new Date(ivt.expires_on);
  };

  const uniqueEmails = invitations.reduce(
    (prev, curr) => {
      const noExist = prev[curr.email] === undefined;
      if (noExist) {
        prev[curr.email] = curr.completed;
      } else if (curr.completed) {
        prev[curr.email] = 1; // mark completed
      }
      return prev;
    },
    {} as { [key: string]: number }
  );

  const uncompletedEmail = Object.keys(uniqueEmails).reduce((prev, curr) => {
    if (!uniqueEmails[curr]) {
      // get un-completed
      prev.push(curr);
    }
    return prev;
  }, [] as string[]);

  const reducedInvitation = uncompletedEmail.map(
    (eml) => invitations.find((i) => i.email === eml) as Invitation
  );

  const hasInvites = reducedInvitation.length > 0;

  return (
    <div className="invitation-list pt-2">
      {hasInvites && (
        <h6 className="mt-2 text-underline">Invitations to confirm:</h6>
      )}
      {hasInvites && (
        <ul className="w-50 ps-2">
          {reducedInvitation.map((ivt, i) => (
            <li
              key={i}
              className="d-flex justify-content-between align-items-center mt-1 pt-1 border-bottom"
            >
              <span className={isExpired(ivt) ? 'text-through' : ''}>
                {ivt.email}
              </span>
              {isExpired(ivt) ? (
                <FontAwesomeIcon icon={faHourglassEnd} size="sm" />
              ) : (
                <FontAwesomeIcon icon={faHourglassStart} size="sm" />
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
