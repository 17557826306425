import { Button } from 'react-bootstrap';
import {
  PlusIcon,
  PencilIcon,
  UserPlusIcon,
} from '@heroicons/react/24/outline';

import { GO } from '@/types';
import { MODAL_TYPES as MDT } from '@/types/options';
import { Company } from '../types';

export const CompanyOperationTools = ({
  openBy,
  company,
}: {
  company: Company;
  openBy: (type: string, context?: GO) => void;
}) => (
  <div className="d-flex justify-content-end gap-2 mb-4">
    {/* === BUTTON-3: invite a user(maybe new) to this comapny */}
    <Button
      title="Invite New User to join company"
      size="sm"
      variant="warning"
      onClick={() => {
        openBy(MDT.INVITE_NEW_USER, { companyProfile: company });
      }}
    >
      <UserPlusIcon className="w-5 h-5" />
      Send User Invitation
    </Button>
    {/* === BUTTON-2: add existed user to this company */}
    <Button
      title="Include a registered user to this company"
      size="sm"
      variant="success"
      onClick={() => openBy(MDT.ADD_CONTRIBUTORS, { companyProfile: company })}
    >
      <PlusIcon className="w-5 h-5" />
      Add an User
    </Button>
    {/* === BUTTON-1: edit company */}
    <Button
      size="sm"
      variant="secondary"
      onClick={() => openBy(MDT.EDIT_GROUP, { companyProfile: company })}
    >
      <PencilIcon className="w-5 h-5" />
      Edit Company
    </Button>
  </div>
);
