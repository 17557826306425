import React, { useState, ChangeEvent } from 'react';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { API_CTX } from '@/config';
import { restoreURL } from '@/lib/axios';
import { queryClient } from '@/lib/react-query';

import { useRoles } from '../api/getRoles';
import { INVITATION_QUERY_KEY } from '../types';

/**
 * Sends a user invitation API call.
 * Required payload:
 * {
    "email": "string",
    "company_id": 0,
    "role_id": 0
 * }
 * @date 2024/09/04
 */
const sendUserInvitation = (payload: {
  email: string;
  company_id: string;
  role_id: string;
}) => {
  const url = `${API_CTX}/user-invitation`;
  return restoreURL().post(url, payload);
};

/**
 * wrap user invitation API and form interaction to this hook!
 * @date 2024/09/04
 */
export const useInvitationAPI = (
  companyId: string,
  closeModal: (evt: any) => void
) => {
  const { roleOptions } = useRoles({});

  const [email, setEmail] = useState('');
  const [role, setRole] = useState<string>(roleOptions[0]?.value);

  const mutation = useMutation({
    onError: (_, __, context: any) => {
      toast.error('Send Invitation Error!');
    },
    onSuccess: (resp: any) => {
      toast.success(resp.detail);
      // to refresh invitation list with `getInvitations` ...
      queryClient.invalidateQueries([INVITATION_QUERY_KEY, companyId]);
    },
    mutationFn: sendUserInvitation,
  });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!email) return;
    const payload = { email, company_id: companyId, role_id: role };
    await mutation.mutateAsync(payload);
    closeModal({});
  };

  const emailChangeHandler = (evt: ChangeEvent<HTMLInputElement>) => {
    setEmail(evt.target.value);
  };

  const roleChangeHandler = (evt: ChangeEvent<HTMLSelectElement>) => {
    setRole(evt.target.value);
  };

  return {
    email,
    roleOptions,
    sending: mutation.isLoading,
    emailChangeHandler,
    roleChangeHandler,
    handleSubmit,
  };
};
