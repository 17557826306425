import { orderBy } from 'lodash';
import Table from 'react-bootstrap/Table';

import { useUsers, User } from '@/features/users';

/**
 * Created at 2024/09/24
 */
export const UserManageTable = () => {
  const userQuery = useUsers();
  const getDay = (createdAt: string) => createdAt.split('T')[0];
  const orderedUser = orderBy(userQuery.data, ['date_created'], ['desc']);
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Username</th>
          <th>Email</th>
          <th>Company</th>
          <th>Role</th>
          <th>Created At</th>
          <th>Operation</th>
        </tr>
      </thead>
      <tbody>
        {orderedUser.map((usr) => (
          <tr key={usr.id}>
            <td>{usr.name}</td>
            <td>{usr.email}</td>
            <td>?</td>
            <td>?</td>
            <td>{getDay(`${usr.date_created}`)}</td>
            <td>
              <button
                type="button"
                className="btn btn-outline-primary btn-sm me-2"
              >
                Set Company
              </button>
              <button type="button" className="btn btn-outline-danger btn-sm">
                Delete
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
